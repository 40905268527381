<template>
  <security-page>
    <template #left>
      <page-illustration :src="illustration" />
    </template>
    <template #right>
      <set-password-form />
    </template>
  </security-page>
</template>

<script>
import SecurityPage from "@/views/security/components/SecurityPage.vue";
import PageIllustration from "@/views/security/components/PageIllustration.vue";
import SetPasswordForm from "@/views/security/components/SetPasswordForm.vue";

export default {
  components: {
    SecurityPage,
    PageIllustration,
    SetPasswordForm,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      illustration: require("@/assets/images/illustrations/new-password.svg"),
    };
  },
};
</script>
