<template>
  <div>
    <b-card-title title-tag="h1" class="mb-1">
      {{ $t("pages.setPassword.title") }} 🔐
    </b-card-title>

    <b-card-text class="mb-5">
      {{ $t("pages.setPassword.subtitle") }}
    </b-card-text>

    <validation-observer ref="setPasswordFormRules" #default="{ invalid }">
      <b-form @submit.prevent="validateForm">
        <!-- new password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="new-password">{{
              $t("pages.setPassword.newPassword")
            }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="newPassword"
            vid="newPassword"
            rules="required|password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="new-password"
                ref="newPassword"
                v-model="newPassword"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-password"
                autocomplete="new-password"
                tabindex="1"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="confirm-password">{{
              $t("pages.setPassword.confirmPassword")
            }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="confirmPassword"
            vid="confirmPassword"
            :rules="`required|confirmpassword:${newPassword}`"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="confirm-password"
                v-model="confirmPassword"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="confirm-password"
                autocomplete="new-password"
                tabindex="2"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          class="mt-5 d-flex align-items-center justify-content-center"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
          tabindex="3"
        >
          <b-spinner v-if="loading" small class="mr-2" />
          <span class="py-1">
            {{ $t("pages.setPassword.button") }}
          </span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, password, confirmpassword } from "@foravila-validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { notifyError } from "@methods";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      newPassword: null,
      confirmPassword: null,
      required,
      password,
      confirmpassword,
    };
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem("foravilaOwnersUserData"));
    },
    userUuid() {
      return this.userData.uuid;
    },
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    emptyForm() {
      return !this.newPassword && !this.confirmPassword;
    },
  },
  watch: {
    currentLanguage() {
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage);
      if (!this.emptyForm) this.$refs.setPasswordFormRules.validate();
    },
    validateForm() {
      this.$refs.setPasswordFormRules.validate().then((success) => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch("user/updateUser", {
              uuid: this.userUuid,
              plainPassword: this.newPassword,
            })
            .then(() => {
              this.$router.push({ name: "foravila-home" });
            })
            .catch((error) => {
              this.$refs.setPasswordFormRules.setErrors(error);
              this.loading = false;
              notifyError(
                this.$t("errors.changePassword.title"),
                this.$t("errors.changePassword.text")
              );
            });
        }
      });
    },
  },
};
</script>
